<template>
  <message-bubble :isMine="isMine" :message="message">
    <div class="custom-element-wrapper">
      <!-- {{ JSON.parse(message.payload.extension) }} -->
      <div
        v-if="extension.type == 'ConsultationSummary'"
        class="custom-summary"
      >
        <div>电子病历</div>
        <div class="summary-content">
          <div class="custom-item">
            <span>主诉：</span>
            <p>
              {{ customInfo.chiefComplaint }}
            </p>
          </div>
          <div class="custom-item">
            <span>处理建议：</span>
            <p>
              {{ customInfo.handlingSuggestions }}
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="extension.type == 'ConsultationPrescription'"
        class="custom-summary"
      >
        <div>电子处方</div>
        <div class="summary-content">
          <div class="prescription-title">
            初步诊断：{{ customInfo.diagnosisName }}
          </div>
          <div
            class="prescription-item"
            v-for="(item, index) in customInfo.medicals"
            :key="index"
          >
            <p class="prescription-item-title">
              {{
                `${index + 1}.${item.medicalName}；${item.quantity}${
                  item.minPackingUnit
                }`
              }}
            </p>
            <p>
              用法用量：{{
                `${item.medicalUsage}${item.frequency} 每次${item.consumption}${item.minUseUnit} 连用${item.medicationDays} ${item.entrust}`
              }}
            </p>
          </div>
        </div>
      </div>

      <div v-if="extension.type == 'ConsultationSubmitOrder'">
        <p class="custom-item">
          <b style="color: #606266; font-size: 16px">
            {{ customInfo.patientName }}</b
          >&#32; <span>{{ customInfo.patientSex == 1 ? '男' : '女' }}</span
          >&#32;

          <span>{{ customInfo.patientAge }}岁</span>
        </p>

        <p class="custom-item">
          复诊患者：
          {{ customInfo.isRevisited ? '是' : '否' }}
        </p>
        <p class="custom-item">
          线下就诊医院：{{ customInfo.visitingHospital || '--' }}
        </p>

        <p class="custom-item">
          主诉：{{ customInfo.consultationContent || '--' }}
        </p>
      </div>

      <div v-if="extension.type == 'ConsultationServing'">问诊已开始</div>

      <div v-if="extension.type == 'SysNotice'">
        {{ this.message.payload.data }}
      </div>
      <div class="survey" v-if="this.payload.data === 'survey'">
        <div class="title">对IM DEMO的评分和建议</div>
        <el-rate
          v-model="rate"
          disabled
          show-score
          text-color="#ff9900"
          score-template="{value}"
        >
        </el-rate>
        <div class="suggestion">{{ this.payload.extension }}</div>
      </div>

      <div v-if="this.text.customEvent === 'CMD_002'">
        <div class="d-header">
          {{ text.customExts.name }}
        </div>
        <div class="d-des">
          {{ text.customExts.description }}
        </div>
      </div>
      <div v-if="this.text.customEvent === 'CMD_013'">
        <img
          v-image-preview
          :src="text.customExts.prescriptionImgUrl"
          alt=""
          class="cmd-img"
          style="width: 200px; height: 287px"
        />
      </div>

      <div v-if="this.text.customEvent === 'CMD_010'">
        <p>问诊已结束</p>
      </div>

      <div v-if="this.payload.description == 'REFERRAL_MSG'">
        <ul class="custom-out" style="min-width: 400px">
          <li v-for="(item, index) in text" :key="index" style="">
            <p
              class="c-name"
              style="font-size: 12px; color: #a5b5c1; line-height: 1px"
            >
              {{ item.senderName }}：
            </p>
            <div class="c-content">
              <img
                v-image-preview
                :src="item.content"
                alt=""
                srcset=""
                v-if="item.type == 4"
                style="width: 100px; height: 100px"
              />
              <p v-else style="color: #606266">
                {{ item.content }}
              </p>
            </div>
          </li>
        </ul>
      </div>

      <div v-if="extension.type == 'customVideo'" class="custom-video">
        <img src="../../../icon/video-uni.png" alt="" srcset="" />
        {{ customInfo.text }}
      </div>
    </div>
  </message-bubble>
</template>

<script>
import { mapState } from 'vuex';
import MessageBubble from '../message-bubble';
import { Rate } from 'element-ui';
import MessageGroupLiveStatus from '../message-group-live-status';

export default {
  name: 'CustomElement',
  data() {
    return {
      jCloudCustomData: {},
    };
  },
  props: {
    payload: {
      type: Object,
      required: true,
    },
    message: {
      type: Object,
      required: true,
    },
    isMine: {
      type: Boolean,
    },
  },
  components: {
    MessageBubble,
    ElRate: Rate,
    MessageGroupLiveStatus,
  },
  computed: {
    ...mapState({
      currentUserProfile: (state) => state.user.currentUserProfile,
      currentConversation: (state) => state.conversation.currentConversation,

      diagnosisList: (state) => state.conversation.diagnosisList,
    }),

    extension() {
      if (this.message.payload && this.message.payload.extension) {
        return JSON.parse(this.message.payload.extension);
      }
      return {};
    },
    customInfo() {
      if (
        [
          'ConsultationSubmitOrder',
          'ConsultationSummary',
          'ConsultationPrescription',
          'customVideo',
        ].includes(this.extension.type)
      ) {
        return JSON.parse(this.message.payload.data);
      }
      return {};
    },
    text() {
      return this.translateCustomMessage(this.message);
    },
    rate() {
      return parseInt(this.payload.description);
    },
  },
  methods: {
    translateCustomMessage(message) {
      const { payload, cloudCustomData } = message;

      if (cloudCustomData) {
        let jCloudCustomData = JSON.parse(cloudCustomData);
        if (
          jCloudCustomData.customEvent === 'CMD_013' ||
          jCloudCustomData.customEvent === 'CMD_002'
        ) {
          if (jCloudCustomData.customEvent === 'CMD_013') {
            let groupID = this.currentConversation.groupProfile.groupID;
            if (!this.diagnosisList.includes(groupID)) {
              this.$store.commit('updateDiagnosisList', groupID);
            }
          }
          return jCloudCustomData;
        }
      }

      //type 消息类型 2-文本 4-图片
      // content 聊天内容 文本为内容，图片为下载地址"
      // senderName 发送人姓名
      // sendDate 发送时间
      // senderUserType 发送人类型 1-患者,2-医生,4-医助"
      let reacordPayload = {};

      if (payload.description == 'REFERRAL_MSG') {
        reacordPayload = JSON.parse(payload.data);

        return reacordPayload;
      }
      let videoPayload = {};
      try {
        videoPayload = JSON.parse(payload.data);
      } catch (e) {
        videoPayload = {};
      }
      if (payload.data === 'group_create') {
        return `${payload.extension}`;
      }
      if (videoPayload.roomId) {
        videoPayload.roomId = videoPayload.roomId.toString();
        videoPayload.isFromGroupLive = 1;
        return videoPayload;
      }
      if (payload.text) {
        return payload.text;
      } else {
        return '[自定义消息1]';
      }
    },
  },

  filters: {
    dateFormate(dataStr) {
      var time = new Date(dataStr);

      function timeAdd0(str) {
        if (str < 10) {
          str = '0' + str;
        }
        return str;
      }
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return (
        y +
        '-' +
        timeAdd0(m) +
        '-' +
        timeAdd0(d) +
        ' ' +
        timeAdd0(h) +
        ':' +
        timeAdd0(mm) +
        ':' +
        timeAdd0(s)
      );
    },
  },
};
</script>

<style lang="stylus" scoped>

.custom-video {
  img {
      width: 28px;
      height: 18.5px;
    }
}
.custom-summary {
    color: #fff;
    .summary-content {
        padding: 2px 5px;
        margin-top: 5px;
        background: #fff;
        border-radius: 2px;

        span {
            font-size: 14px;
            color: black;
            display: block;
            margin-bottom: 4px;
        }
        p {
            margin: 0px 0px 4px 4px;
            // text-indent: 4px;
        }
    }
    .prescription-title {
        color: black;
        font-weight: bold;
        margin: 4px 0;
        font-size: 14px;
    }
    .prescription-item {
        color: #909399;
        font-size: 14px;
        .prescription-item-title {
            color: black;
        }
    }
}
.custom-item
    color: #909399;
    font-size: 14px;
    padding-top: 4px;
.d-header
  border-bottom 1px solid #a5b5c1
  padding-bottom 3px
.d-des
  font-size 12px
  padding-top 5px
.custom-out
  width 100%
  padding-left 0
  li
    list-style none
    color red
    font-size 12px
.text
  font-weight bold
.title
  font-size 16px
  font-weight 600
  padding-bottom 10px
.survey
  background-color white
  color black
  padding 20px
  display flex
  flex-direction column
.suggestion
  padding-top 10px
  font-size 14px
</style>
